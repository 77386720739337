import { fromRedirect } from './fromRedirect';

import { storage } from '../storage';
import { ACTION_USED, STORAGE_3DS_DATA } from '../../constants/paymentMethodsHub';

import { PaymentMethodsHubComposable } from '../PaymentMethodsHubComposable';

jest.mock('../PaymentMethodsHubComposable', () => ({
  PaymentMethodsHubComposable: jest.fn().mockResolvedValue({}),
}));

PaymentMethodsHubComposable.mockImplementation(() => ({
  getProfileInfo: jest.fn(() => ({
    profile: 'user',
  })),
}));

const storeRedirectPaymentMethodMock = jest.fn();
const postConfirmRedirectMock = jest.fn();

const utils = {
  redirectQuery: '',
  storeRedirectPaymentMethod: storeRedirectPaymentMethodMock,
  getRedirectResult: jest.fn().mockResolvedValue({
    MD: 'md',
    PaRes: 'pa_res',
    redirectResult: 'redirectResult',
  }),
  postConfirmRedirect: postConfirmRedirectMock,
};

const currentPath = '';

const storeData = {
  userData: {
    uuid: 'uuid',
  },
  profile: {},
  CSOperator: {},
};

storage.add(STORAGE_3DS_DATA, {
  resourceType: null,
  resourceUuid: null,
});

storage.add(ACTION_USED, 'add');

const callbackFn = jest.fn();

describe('When the "fromRedirect" method is called', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  describe('And the url contains "3ds1createpaymentmethod" ', () => {
    describe('And the "confirmRedirectResponse" response is not "redirectShopper"', () => {
      it('Then the "storeRedirectPaymentMethod" method is called', async () => {
        await fromRedirect(utils, currentPath, storeData, callbackFn);

        expect(storeRedirectPaymentMethodMock).toHaveBeenCalled();
      });
    });

    describe('And is a payment', () => {
      it('Then the "postConfirmRedirect" method is called', async () => {
        storage.add(STORAGE_3DS_DATA, {
          resourceType: 'type',
          resourceUuid: 'uuid',
          reference: 'ref',
        });

        await fromRedirect(utils, currentPath, storeData, callbackFn);

        expect(postConfirmRedirectMock).toHaveBeenCalled();
      });
    });
  });
});
